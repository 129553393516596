import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box } from "@material-ui/core";
import Slide from '@material-ui/core/Slide';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { regexForSixDigitsAndNoValueAfterDecimal } from '../utils/utils';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomizedAlertDialog(props) {

  const [value, setValue] = useState('');
  const [textField, setShowTextField] = useState(false);
  const [noOfParticipants, setNoOfParticipants] = useState();
  const [errorMsg, setErrorMsg] = useState('');

  const handleChange = (event) => {
    props.sendValueToDetailSimulation(event.target.value);
    setValue(event.target.value);
    if (event.target.value === "synchronous") {
      setShowTextField(true);
      setNoOfParticipants("");
    } else if (event.target.value === "asynchronous") {
      setShowTextField(false);
      setErrorMsg('');
      props.sendNoOfParticipants(0);
    }
  };

  const handleNoOfParticipants = (e) => {
    if (regexForSixDigitsAndNoValueAfterDecimal.test(e.target.value === "" ? 0 : e.target.value)) {
      props.sendNoOfParticipants(e.target.value);
      setNoOfParticipants(e.target.value);
    }
  }

  const handleSkipForNow = () => {
    props.handleSkipForNow();
  }

  const handleSubmit = () => {
    if ((noOfParticipants === '' || noOfParticipants === undefined) && value === 'synchronous') {
      setErrorMsg('Please enter no of participants');
      return;
    }
    props.handleSubmit();
  }
  
  return (
    <div>
      <Dialog
        open={props.show}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box display="flex" alignItems="center">
          {props.icon}
          <DialogTitle id="alert-dialog-slide-title" className="dialog-title">{props.title}</DialogTitle>
        </Box>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.children}
          </DialogContentText>
          {props.showRadioButtons &&
            <FormControl component="fieldset">
              <RadioGroup aria-label="simPlayType" name="simPlayTime" value={value} onChange={handleChange}>
                <FormControlLabel value="synchronous" control={<Radio />} label="Synchronous" />
                <FormControlLabel value="asynchronous" control={<Radio />} label="Asynchronous" />
              </RadioGroup>
            </FormControl>
          }
          {textField &&
            <div className='form-group'>
              <label> No of Participants: </label>
              <div>
                <input
                  type="text"
                  onChange={handleNoOfParticipants}
                  value={noOfParticipants}
                  required
                  placeholder='No of participants'
                  pattern={/^[0-9]+$/}
                />
              </div>
              <div className='msg-error'>
                {((noOfParticipants === "" || noOfParticipants === undefined) && errorMsg) &&
                  "No of participants are required."}
              </div>
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSkipForNow}
            className="dialog-button-skip-for-now"
          >
            {props.positiveButtonTitle ? props.positiveButtonTitle : "Yes"}
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            className='dialog-button-submit'
            disabled={!value ? true : false}>
            {props.negativeButtonTitle ? props.negativeButtonTitle : "No"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
