import { useState, useEffect } from 'react';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import DescriptionIcon from '@material-ui/icons/Description';
import { Player } from 'video-react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { MAX_CHARACTER_LIMITS_FOR_ELIPSIS } from '../../../utils/utils';
import Rating from '@material-ui/lab/Rating';
import { makeStyles } from '@material-ui/core/styles';
import AppService from '../../../services/app.service';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '& > * + *': {
            marginTop: theme.spacing(1),
        },
    },
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" align='center'>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        width: "450px",
        height: "350px"
    },
}))(MuiDialogContent);

const ViewSimPage = (props) => {

    const classes = useStyles();

    const [showFullText, setShowFullText] = useState(false);

    const [open, setOpen] = useState(false);

    const [ratings, setRatings] = useState(0);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const renderDescription = () => {
        const isOverMaxChars = props.detailText?.length > MAX_CHARACTER_LIMITS_FOR_ELIPSIS;

        const truncatedText = isOverMaxChars ? props.detailText.slice(0, MAX_CHARACTER_LIMITS_FOR_ELIPSIS) + "..." : props.detailText;

        const toggleText = () => {
            setShowFullText(!showFullText);
        };

        return (
            <div >
                <span>
                    {showFullText ? props.detailText : truncatedText}
                    {isOverMaxChars && (
                        <button onClick={toggleText} className='more-button'>
                            {showFullText ? "Less" : "More"}
                        </button>
                    )}
                </span>
            </div>
        );
    };

    const documents = [
        { file: props.docFile1, access: props.docAccess1, name: props.docName1 },
        { file: props.docFile2, access: props.docAccess2, name: props.docName2 },
        { file: props.docFile3, access: props.docAccess3, name: props.docName3 },
        { file: props.docFile4, access: props.docAccess4, name: props.docName4 },
        { file: props.docFile5, access: props.docAccess5, name: props.docName5 }
    ];

    const playSimulations = async () => {
        await props.playSimulations();
    };

    const checkExistenceOfDocuments = () => {
        const simDocumentsList = documents && documents.map((doc) => (doc.file && props.hasDocumentAccess(doc.access, props.role)));
        const documentsExistOrNot = simDocumentsList?.every(document => !document);

        const simFacilitatorDocuments = props.simFacilitatorDocumentsListBySimId && props.simFacilitatorDocumentsListBySimId.map((doc) => (
            doc.url && props.hasDocumentAccess(doc.access, props.role))
        );
        const simDocumentsExistOrNot = simFacilitatorDocuments?.every(globalDoc => !globalDoc);

        if (documentsExistOrNot && simDocumentsExistOrNot) {
            return true;
        }
        return false;
    }

    const loadData = async () => {
        const avgFeedback = await AppService.get(props.simId, 'sims/getAverageRatingsBySim');
        setRatings(avgFeedback ? avgFeedback : 0);
    }

    useEffect(() => {
        loadData();
    })

    return (
        <>
            <div className='view-sim-page-container' >
                <div className='content-view-page'>
                    <div className="heading-view-sim-page">
                        {props.simName}
                    </div>

                    <div className="sim-duration-view-sim-page">
                        {!props.message &&
                            <div className={classes.root}>
                                <Rating name="half-rating-read" value={ratings}
                                    precision={0.5}
                                    readOnly
                                    className='ratings-view-sim-page'
                                />
                            </div>
                        }
                        {props.simDuration !== "" && (
                            <div>
                                ~{props.simDuration}
                            </div>
                        )}
                    </div>

                    {props.message !== '' && <h2>{props.message}</h2>}
                    <div className="description-view-sim-page">
                        {renderDescription()}
                    </div>
                    <div className='margin-for-buttons'>
                        {(props.loggedIn || props.validUser) && (props.loggedIn || props.validUser) && props.role === "Role_Admin" && (props.email === props.createdBy || props.email === "")
                            ? <PlayCircleFilledIcon
                                className='play-icon-view-sim-page'
                                onClick={() => props.handleAlertDialog()}
                            />
                            : (props.loggedIn || props.validUser) && props.message === "" &&
                            <PlayCircleFilledIcon
                                className='play-icon-view-sim-page'
                                onClick={() => playSimulations()}
                            />
                        }
                        {!props.message &&
                            <>
                                <div className='play-sim-heading-view-sim'>
                                    Play Sim
                                </div>
                                <DescriptionIcon
                                    className='description-icon-view-sim-page'
                                    onClick={handleClickOpen}
                                />
                                <div className='play-sim-heading-view-sim'>
                                    View Documentation
                                </div>
                            </>
                        }
                        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                            <DialogTitle id="customized-dialog-title" onClose={handleClose} className='close-dialog-view-sim'>
                                Documents
                            </DialogTitle>
                            <DialogContent dividers>
                                {checkExistenceOfDocuments() ? (
                                    <Typography className="no-documents-message">No File Exists.</Typography>
                                ) : (
                                    <>
                                        {documents.map((doc, index) => (
                                            doc.file && props.hasDocumentAccess(doc.access, props.role) && (
                                                <Typography key={index} gutterBottom className='icons-text-display'>
                                                    <DescriptionIcon className='dialog-desciption-icon margin-in-icons' />
                                                    <a href="#" data-name={doc.file} onClick={event => props.openDocument(event)} className='doc-name-view-sim-page'>
                                                        {doc.name}
                                                    </a>
                                                </Typography>
                                            )
                                        ))}
                                        {props.simFacilitatorDocumentsListBySimId &&
                                            props.simFacilitatorDocumentsListBySimId.map((doc, i) => {
                                                const docDetail = doc;
                                                return (
                                                    docDetail.url && props.hasDocumentAccess(docDetail.access, props.role) && (
                                                        <Typography key={i} gutterBottom className="icons-text-display">
                                                            <DescriptionIcon className="dialog-desciption-icon margin-in-icons" />
                                                            <a
                                                                href="#"
                                                                className='global-doc-view-page doc-name-view-sim-page'
                                                                data-name={docDetail.url}
                                                                onClick={(event) => props.openFacilitatorDocument(event)}
                                                            >
                                                                {docDetail.docName}
                                                            </a>
                                                        </Typography>
                                                    )
                                                );
                                            })}
                                    </>
                                )}
                            </DialogContent>
                        </Dialog>
                    </div>
                </div >

                {!props.message &&
                    <>
                        <div className='additional-data' >
                            <section className="simulations-section" >
                                <div className="simulations wide">
                                    <div className="simulation-blanket">
                                        <div className="simulation-video-box">
                                            <div className="close-section">CLOSE <i className="fa fa-times"></i></div>
                                            <div className="inner">
                                                <div className="embed-container">
                                                    <Player
                                                        className='simulation-video'
                                                        playsInline
                                                        src={`/video/${props.preview}`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="simulation single simulation-view-page single-view-page">
                                        <div className="poster " style={{ marginTop: "15%", width: "490px", height: "300px", backgroundImage: "url(" + `/images/${props.thumbnail}` + ")" }}>
                                            <div className={`playhead ${props.message == "" && 'single_sim_playhead'}`}>
                                                <div className="icon"> <i className="fa fa-play-circle"></i></div>
                                                <div className="playhead-title">Preview</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </>}
            </div >
        </>
    );
}

export default ViewSimPage;
