import React from 'react';
import IFRAME from '../../../components/iframe';
import Modal from '../../../components/modal';
import { LOCAL_SIM_URL } from '../../../constants';
import auth from '../../../services/auth';
import Link from '@material-ui/core/Link';
import { Tooltip } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { HELP_PAGE_LINK } from "../../../constants";

class ViewSimulation extends React.Component<any, any> {

    state = {
        simId: '',
        url: '',
        linkId: 0,
        simPlayTime: 0,
        isShowingModal: false,
        isLoggedIn: false,
        linkAuditId: 0
    }

    loadData = () => {
        let isCurrentUserLoggedIn = false;
        try {
            isCurrentUserLoggedIn = auth.isLoggedIn()
        } catch (ex) {
            console.error("[ViewSimulationPage] Current User not loggedIn:", ex);
        }

        this.setState({
            simId: this.props['match'].params.simId,
            url: this.props.location.pathname,
            linkId: this.props['match'].params.linkId ? this.props['match'].params.linkId : 0,
            simPlayTime: new Date().getTime(),
            isLoggedIn: isCurrentUserLoggedIn,
            linkAuditId: this.props['match'].params.linkAuditId ? this.props['match'].params.linkAuditId : 0,
        });
    }

    componentDidMount = () => {
        this.loadData();
    }

    handelFeedback = () => {
        this.props.history.push(`/public/feedback/${this.state.linkId}/${this.state.linkAuditId}`);
    }

    handelCloseSim = () => {
        const simPT = (new Date().getTime() - this.state.simPlayTime) / 60000;
        if (this.state.linkId != 0 && simPT > 5) {
            this.props.history.push(`/public/feedback/${this.state.linkId}/${this.state.linkAuditId}`);
        } else {
            this.props.history.push('/visiting');
        }
    }

    handleClose = () => {
        this.setState({ isShowingModal: false });
    }

    handleBlankScreen = () => {
        window.location.reload();
    }

    render() {
        return (
            <div>
                <IFRAME src={`${LOCAL_SIM_URL}/${this.state.simId}`} />

                <div className="row p-1 pr-1">
                    <div className='col-sm-6'>
                        <div className='help-icon-view'>
                            <Tooltip title="Frequently Asked Question" className='content-body'>
                                <Link href={HELP_PAGE_LINK} target="_blank" className='help-icon-clr-view-sim'>
                                    <HelpOutlineIcon style={{ fontSize: 35 }} />
                                </Link>
                            </Tooltip>
                        </div>
                    </div>
                    <div className='col-sm-6 '>
                        {this.state.linkId != 0 && this.state.linkId != null &&
                            <div className='text-right'>
                                <button className='btn btn-primary buttons' onClick={() => { this.setState({ isShowingModal: true }) }}>Close</button>
                                <button className='btn btn-primary ml-2 buttons' onClick={this.handelFeedback}>Feedback</button>
                            </div>
                        }
                    </div>
                </div>
                <Modal show={this.state.isShowingModal} handleNo={this.handleClose} handleYes={this.handelCloseSim} >
                    <p>Are you sure you want to close this sim?</p>
                </Modal>
            </div>
        );
    }
}

export default ViewSimulation;